import styled from 'styled-components';

export const Root = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 20;
    width: 100%;
    height: 10vh;
    background-color: #be9063;
    align-items: flex-end;
    .logo {
        height: 50px;
        margin: 0 5vh;
    }
    .logoFooter {
        height: 50px;
        margin: 0 5vh;
    }
    @media(max-width: 450px) {
        .logo {
                display: none;    
        };    
   `
