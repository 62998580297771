import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Logo-chico.png";
import { Barrita } from './styled';

const NavBar = () => {


    return (
        <Barrita>
                <img 
                className="logo"
                src={logo} 
                alt="Logo Quattro Castello"/>
            <div className="title">Quienes Somos?</div>
            <div className="title">Nuestras Plantas</div>
            <div className="title">Contacto</div>
        </Barrita>
    );
};

export default NavBar;