import styled from 'styled-components';

export const Barrita = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    width: 100%;
    height: 10vh;
    background-color: #be9063;
    justify-content: space-around;
    .logo {
        height: 10vh;
        width: 16vh;
    };
    .title {
        text-decoration: none;
        color: whitesmoke;
    };
    @media(max-width: 720px) {
        .logo {
            display: none;    
        };  

   };
   @media(max-width: 450px) {
         .logo {
                 display: none;    
         };    
        .title {
        font-size: 12px;
    };  
}
   `
