import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    text-align: center;
    .imgCarrousel {
        height: 76vh;
        display: block;
        overflow: hidden;
        display: flex;
        margin: auto;
        object-fit: cover;
        width: 80%;
    };
    .header {
        display: flex;
        align-items: center;
        height: 50;
        justifyContent: space-around;
      };
    .container {
        display: flex;
        flex-direction: row; 
    }
    .color__cremita {
        color: whitesmoke;
    };
    .puntitos {
        display: flex;
        justifyContent: space-around;
    }
   `
