import React from 'react';
import logo from '../../assets/Logo-chico.png';
import whatsapp from '../../assets/whatsapp.png';
import mail from '../../assets/mail.png';
import { Root } from './styled';

const Footer = () => {
  return (
    <Root>
      <div>
        <img
          className="logo"
          src={logo}
        alt="Logo Quattro Castello"
        ></img>

      </div>
    <div>

        <img
          className="logoFooter"
          src={mail}
        alt="Logo Quattro Castello"
        ></img>
                <img
          className="logoFooter"
          src={whatsapp}
        alt="Logo Quattro Castello"
        ></img>
    </div>
    </Root>
  );
}

export default Footer;