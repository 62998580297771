import React from 'react';
import Carrousel from '../carrousel/carrousel.jsx';
import Footer from '../footer/footer.jsx';
import NavBar from '../navbar/navbar.jsx';
import { Container } from './styled';

function Home() {

  return (

    <Container>
      <NavBar></NavBar>
      <Carrousel></Carrousel>
      <Footer></Footer>
    </Container>
  );
}

export default Home;
