import React from 'react';
import {
  useTheme,
  MobileStepper,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import image1 from '../../assets/carrousel1.jpg';
import image2 from '../../assets/carrousel2.jpg';
import image3 from '../../assets/carrousel3.jpg';
import image4 from '../../assets/carrousel4.jpg';
import image5 from '../../assets/carrousel5.jpg';
import { Root } from './styled';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    imgPath: image1,
  },
  {
    imgPath: image2,
  },
  {
    imgPath: image3,
  },
  {
    imgPath: image4,
  },
  {
    imgPath: image5,
  },
];

function Carrousel() {

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    console.log(maxSteps, activeStep)
    //Manejador de la próxima imágen del carrusel
    if (activeStep === (maxSteps - 1)) {
      setActiveStep((prevActiveStep) => (prevActiveStep = 0));
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    //Manejador de la imágen previa del carrusel
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => (prevActiveStep = (maxSteps - 1)));
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    //Renderizado del carrusel de imágenes
    <Root>
      {/* <Paper square elevation={0} className="header">
        <Typography>{tutorialSteps[activeStep].label}</Typography>
      </Paper> */}
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >

        {tutorialSteps.map((step, index) => (
          <div className="container" key={index}>
            <Button size="small" className="color__cremita" onClick={handleBack}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
            {Math.abs(activeStep - index) <= (maxSteps - 1) ? (
              <img
                className="imgCarrousel"
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
            <Button size="small" className="color__cremita" onClick={handleNext}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          </div>
        ))}
      </AutoPlaySwipeableViews>
      
      {/* TODO: Dejar los puntitos centrados
      <div className="puntitos">
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}

      />

      </div> */}
    </Root>
  );
}

export default Carrousel;